import { UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, MenuProps } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { SessionTimeoutModal } from 'src/components/SessionTimeout'
import { getRouteNameFromPath, goToPage, RouteName } from 'src/routes'
import { useLogoutMutation } from 'src/store/APIs/auth'
import { useMeQuery } from 'src/store/APIs/misc'
import { UserType } from 'src/store/APIs/types'

import styles from './navBar.module.scss'
import { strings } from './strings'

const HomeMenu = {
  key: RouteName.Home,
  label: strings.dashboardLabel,
}
const UsersMenu = {
  children: [
    { key: RouteName.Survivors, label: strings.survivorsLabel },
    {
      key: RouteName.CarePartners,
      label: strings.carePartnersLabel,
    },
  ],
  key: strings.usersLabel,
  label: strings.usersLabel,
}
const CommunityGroupsMenu = {
  key: RouteName.CommunityGroups,
  label: strings.communityGroupsLabel,
}
const ConfigurationMenu = {
  children: [
    {
      key: RouteName.Learn,
      label: strings.learnLabel,
    },
    {
      key: RouteName.SurvivorProfile,
      label: strings.survivorProfileLabel,
    },
    {
      key: RouteName.DefaultNavigatorTasks,
      label: strings.defaultNavigatorTasksLabel,
    },
    {
      key: RouteName.RetailProducts,
      label: strings.retailProductsLabel,
    },
    {
      key: RouteName.SuicidalityEmails,
      label: strings.suicidalityEmails,
    },
  ],
  key: strings.configurationLabel,
  label: strings.configurationLabel,
}

const DefaultMenu = [HomeMenu, UsersMenu, CommunityGroupsMenu]

const AdminMenu = [HomeMenu, UsersMenu, CommunityGroupsMenu, ConfigurationMenu]

export const NavBar: React.FC = () => {
  const [triggerLogout, { isSuccess }] = useLogoutMutation()
  const { data } = useMeQuery()

  const onClickMenu = ({ key }: { key: string }) => {
    goToPage(key as RouteName)
  }

  const menus = useMemo(() => {
    if (data) {
      switch (data?.user.user_type) {
        case UserType.admin:
          return AdminMenu
        case UserType.cms_navigator:
          return DefaultMenu
        default:
          return DefaultMenu
      }
    }
    return DefaultMenu
  }, [data])

  const items: MenuProps['items'] = useMemo(
    () => [
      { key: '1', label: data?.user.email },
      {
        key: '2',
        label: strings.logout,
        onClick: () => triggerLogout(),
      },
    ],
    [data],
  )

  useEffect(() => {
    if (isSuccess) {
      goToPage(RouteName.Login)
    }
  }, [isSuccess])

  const location = useLocation()
  const { pathname } = location

  return (
    <>
      <SessionTimeoutModal triggerLogout={triggerLogout} />
      <div className={styles.navBarContainer}>
        {/* TODO: when we have typographies use that instead of this style */}
        <div className={styles.navBarTitle}>{strings.navBarTitle}</div>
        <Menu
          defaultSelectedKeys={[getRouteNameFromPath(pathname) ?? '']}
          mode="horizontal"
          theme="dark"
          className={styles.menu}
          items={menus}
          onClick={onClickMenu}
        />
        <Dropdown menu={{ items }} arrow placement="bottom">
          <Avatar
            size="large"
            icon={<UserOutlined />}
            className={styles.avatar}
          />
        </Dropdown>
      </div>
    </>
  )
}
