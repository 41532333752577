import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { SubHeader } from 'src/components/SubHeader'
import { useDrawer } from 'src/hooks/useDrawer'
import { RouteName } from 'src/routes'
import { useGetImpairmentsQuery } from 'src/store/APIs/impairments'
import {
  useAddRetailProductMutation,
  useDeleteRetailProductMutation,
  useEditRetailProductMutation,
  useGetRetailCategoriesQuery,
  useLazyGetRetailProductQuery,
  useLazyGetRetailProductsQuery,
} from 'src/store/APIs/retailProducts'

import { areFiltersOn } from '../Learn/types'
import { ImpairmentsDrawer } from '../SurvivorProfile/Impairments/ImpairmentsDrawer'
import { AddRetailProduct } from './AddRetailProduct'
import { useRetailProductsColumns } from './columns'
import { RetailCategoriesDrawer } from './RetailCategoriesDrawer'
import styles from './retailProducts.module.scss'
import { strings } from './strings'
import { ViewRetailProduct } from './ViewRetailProduct'

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: strings.title, path: RouteName.RetailProducts },
]

export const RetailProducts: React.FC = () => {
  const { data: impairmentsData } = useGetImpairmentsQuery({})
  const { data: retailCategoriesData } = useGetRetailCategoriesQuery({})

  const {
    open: openCategory,
    onCloseDrawer: onCloseCategoryDrawer,
    selectedId: selectedCategoryId,
    openView: openCategoryView,
    showDrawer: showCategoryDrawer,
  } = useDrawer()

  const [triggerAddRetailProductMutation, { isLoading: loadingAddProduct }] =
    useAddRetailProductMutation()
  const [triggerEditRetailProductMutation, { isLoading: loadingEditProduct }] =
    useEditRetailProductMutation()
  const [
    triggerDeleteRetailProductMutation,
    { isLoading: loadingDeleteProduct },
  ] = useDeleteRetailProductMutation()

  const isLoadingRightButtonProduct = loadingAddProduct || loadingEditProduct

  const {
    open: openProduct,
    onCloseDrawer: onCloseProductDrawer,
    selectedId: selectedProductId,
    openView: openProductView,
    showDrawer: showProductDrawer,
  } = useDrawer()

  const {
    open: openImpairment,
    onCloseDrawer: onCloseImpairmentDrawer,
    selectedId: selectedImpairmentId,
    openView: openImpairmentView,
  } = useDrawer()

  const { resetFilters, ...columnsAndFilters } = useRetailProductsColumns(
    impairmentsData,
    retailCategoriesData,
    openCategoryView,
    openProductView,
    openImpairmentView,
  )
  const filtersOn = areFiltersOn(columnsAndFilters.filteredInfo.filters)

  return (
    <div className={styles.container}>
      <SubHeader
        className={styles.subHeader}
        routes={mockedBreadcrumbRoutes}
        section={SectionNames.Configuration}
        title={strings.title}>
        <div className={styles.subHeaderButtons}>
          <Button onClick={resetFilters} disabled={!filtersOn}>
            {generalStrings.clearFiltersButtonLabel}
          </Button>
          <Button onClick={showCategoryDrawer}>
            {strings.addRetailCategoryButton}
          </Button>
          <Button onClick={showProductDrawer} type="primary">
            {strings.addRetailProductButton}
          </Button>
        </div>
      </SubHeader>
      <RetailCategoriesDrawer
        open={openCategory}
        onCloseDrawer={onCloseCategoryDrawer}
        selectedId={selectedCategoryId}
      />
      <DrawerWithState
        open={openProduct}
        onCloseDrawer={onCloseProductDrawer}
        selectedId={selectedProductId}
        RenderAdd={AddRetailProduct}
        RenderView={ViewRetailProduct}
        resourceName={strings.retailProductTitle}
        useLazyGetResource={useLazyGetRetailProductQuery}
        triggerAddResourceMutation={triggerAddRetailProductMutation}
        triggerEditResourceMutation={triggerEditRetailProductMutation}
        isLoadingRightButton={isLoadingRightButtonProduct}
        triggerDeleteResourceMutation={triggerDeleteRetailProductMutation}
        isLoadingLeftButton={loadingDeleteProduct}
        deleteModalTitle={strings.deleteProductModalTitle}
      />
      <ImpairmentsDrawer
        open={openImpairment}
        selectedId={selectedImpairmentId}
        onCloseDrawer={onCloseImpairmentDrawer}
      />
      <PaginatedTable
        className={styles.table}
        useLazyQuery={useLazyGetRetailProductsQuery}
        {...columnsAndFilters}
      />
    </div>
  )
}
