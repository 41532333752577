import { generalStrings } from 'src/common/generalStrings'
import { RouteName } from 'src/routes'
import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
  triggerEntityNotFound,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Comment,
  CommentPayload,
  CommunityGroup,
  CommunityGroupDetail,
  Endpoints,
  getCommunityGroupDeserializer,
  getCommunityGroupMembersDeserializer,
  getCommunityGroupPostCommentsDeserializer,
  getCommunityGroupPostsDeserializer,
  getCommunityGroupsDeserializer,
  Member,
  Post,
  PostPayload,
} from './types'

export const getCommunityGroups = (builder: Builder) =>
  builder.query<PaginatedItems<CommunityGroup>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.CommunityGroups),
    query: endpointWithFilters(Endpoints.GetCommunityGroups),
    transformResponse: getCommunityGroupsDeserializer,
  })

export const getCommunityGroup = (builder: Builder) =>
  builder.query<CommunityGroup, string>({
    providesTags: (_, __, id) => [{ id, type: Tags.CommunityGroups }],
    query: id => format(Endpoints.GetCommunityGroup, id),
    transformErrorResponse: triggerEntityNotFound(
      RouteName.CommunityGroups,
      generalStrings.communityGroupNotFound,
    ),
    transformResponse: ({ group }) => getCommunityGroupDeserializer(group),
  })

export const getCommunityGroupMembers = (builder: Builder) =>
  builder.query<PaginatedItems<Member>, PaginatedParams>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.CommunityGroups }],
    query: paginatedParams(Endpoints.GetCommunityGroupMembers),
    transformResponse: getCommunityGroupMembersDeserializer,
  })

export const newCommunityGroup = (builder: Builder) =>
  builder.mutation<CommunityGroup, CommunityGroupDetail>({
    invalidatesTags: provideListToTag(Tags.CommunityGroups),
    query: group => {
      const formData = new FormData()
      formData.append('group[name]', group.name)

      if (group.description) {
        formData.append('group[description]', group.description)
      }

      if (group.cms_navigator_ids) {
        group.cms_navigator_ids.forEach(nav_id => {
          formData.append(`group[cms_navigator_ids][]`, nav_id)
        })
      }

      if (group.mobile_user_ids) {
        group.mobile_user_ids.forEach(user_id => {
          formData.append(`group[mobile_user_ids][]`, user_id)
        })
      }

      if (group.image) {
        formData.append('group[image]', group.image)
      }

      return {
        body: formData,
        method: HttpMethod.Post,
        url: Endpoints.NewCommunityGroup,
      }
    },
  })

export const editCommunityGroup = (builder: Builder) =>
  builder.mutation<CommunityGroup, { group: CommunityGroupDetail; id: string }>(
    {
      invalidatesTags: () => [{ type: Tags.CommunityGroups }],
      query: ({ group, id }) => {
        const formData = new FormData()
        formData.append('group[name]', group.name)

        if (group.description) {
          formData.append('group[description]', group.description)
        }

        if (group.cms_navigator_ids) {
          group.cms_navigator_ids.forEach(nav_id => {
            formData.append(`group[cms_navigator_ids][]`, nav_id)
          })
        }

        if (group.mobile_user_ids) {
          group.mobile_user_ids.forEach(user_id => {
            formData.append(`group[mobile_user_ids][]`, user_id)
          })
        }

        if (!group.image) {
          formData.append('group[image]', '')
        } else if (group.image && typeof group.image !== 'string') {
          formData.append('group[image]', group.image)
        }

        return {
          body: formData,
          method: HttpMethod.Put,
          url: format(Endpoints.EditCommunityGroup, id),
        }
      },
    },
  )

export const deleteCommunityGroup = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    invalidatesTags: provideListToTag(Tags.CommunityGroups),
    query: id => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteCommunityGroup, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const getGroupPosts = (builder: Builder) =>
  builder.query<Post[], string>({
    providesTags: provideListToTag(Tags.GroupPosts),
    query: id => ({
      method: HttpMethod.Get,
      url: format(Endpoints.GetGroupPosts, id),
    }),
    transformResponse: getCommunityGroupPostsDeserializer,
  })

export const newGroupPost = (builder: Builder) =>
  builder.mutation<Post, PostPayload>({
    invalidatesTags: provideListToTag(Tags.GroupPosts),
    query: ({ groupId, body }) => {
      const formData = new FormData()
      if (body.content) {
        formData.append('post[content]', body.content)
      }
      if (body.image) {
        formData.append('post[image]', body.image)
      }
      return {
        body: formData,
        method: HttpMethod.Post,
        url: format(Endpoints.NewGroupPost, groupId),
      }
    },
  })

export const deleteGroupPost = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { groupId: string; postId: string }>({
    invalidatesTags: provideListToTag(Tags.GroupPosts),
    query: ({ groupId, postId }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteGroupPost, groupId, postId),
    }),
    transformResponse: performActionTransformResponse,
  })

export const likeGroupPost = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { groupId: string; body: { activity_id: string } }
  >({
    invalidatesTags: provideListToTag(Tags.GroupPosts),
    query: ({ groupId, body }) => ({
      body,
      method: HttpMethod.Post,
      url: format(Endpoints.LikeGroupPost, groupId),
    }),
  })

export const unlikeGroupPost = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { groupId: string; body: { activity_id: string; reaction_id: string } }
  >({
    // TODO: check if it is possible to do it individually
    invalidatesTags: provideListToTag(Tags.GroupPosts),
    query: ({ groupId, body }) => ({
      body,
      method: HttpMethod.Delete,
      url: format(Endpoints.UnlikeGroupPost, groupId),
    }),
  })

export const likePostComment = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { groupId: string; body: { activity_id: string; comment_id: string } }
  >({
    invalidatesTags: provideListToTag(Tags.GroupPosts),
    query: ({ groupId, body }) => ({
      body,
      method: HttpMethod.Post,
      url: format(Endpoints.LikePostComment, groupId),
    }),
  })

export const unlikePostComment = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    {
      groupId: string
      body: { activity_id: string; comment_id: string; reaction_id: string }
    }
  >({
    invalidatesTags: provideListToTag(Tags.GroupPosts),
    query: ({ groupId, body }) => ({
      body,
      method: HttpMethod.Delete,
      url: format(Endpoints.UnlikePostComment, groupId),
    }),
  })

export const getPostComments = (builder: Builder) =>
  builder.query<Comment[], { groupId: string; postId: string }>({
    providesTags: (_, __, { postId }) => [
      { id: postId, type: Tags.CommunityGroups },
    ],
    query: ({ groupId, postId }) => ({
      method: HttpMethod.Get,
      url: format(Endpoints.GetPostComments, groupId, postId),
    }),
    transformResponse: getCommunityGroupPostCommentsDeserializer,
  })

// TODO: Find a way to have some kind of serializer for using formData
export const newPostComment = (builder: Builder) =>
  builder.mutation<PerformActionResponse, CommentPayload>({
    invalidatesTags: (_, __, { postId }) => [
      { id: postId, type: Tags.CommunityGroups },
    ],
    query: ({ groupId, postId, body }) => {
      const formData = new FormData()
      formData.append(
        'post_comment[activity_foreign_id]',
        body.activityForeignId,
      )
      formData.append('post_comment[content]', body.message ?? '')
      if (body.image) {
        formData.append('post_comment[attachments]', body.image)
      }
      return {
        body: formData,
        method: HttpMethod.Post,
        url: format(Endpoints.NewPostComment, groupId, postId),
      }
    },
  })

export const deletePostComment = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    {
      groupId: string
      postId: string
      commentId: string
      body: { post_comment: { activity_foreign_id: string } }
    }
  >({
    invalidatesTags: (_, __, { postId }) => [
      { id: postId, type: Tags.CommunityGroups },
    ],
    query: ({ groupId, postId, commentId, body }) => ({
      body,
      method: HttpMethod.Delete,
      url: format(Endpoints.DeletePostComment, groupId, postId, commentId),
    }),
    transformResponse: performActionTransformResponse,
  })
