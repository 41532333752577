import {
  Entity,
  Identifiable,
  PaginatedItems,
  PaginationResponse,
  Permissions,
} from '../types'

export enum Endpoints {
  GetImpairments = '/cms/v1/global_settings/impairments.json',
  GetSurvivorImpairments = '/cms/v1/mobile_survivors/%s/profile/impairments.json?page=%s',
  UpdateSurvivorsImpairments = '/cms/v1/mobile_survivors/%s/profile/impairments/batch_put.json',
  GetImpairment = '/cms/v1/global_settings/impairments/%s.json',
  DeleteImpairment = '/cms/v1/global_settings/impairments/%s.json',
  UpdateImpairment = '/cms/v1/global_settings/impairments/%s.json',
  AddImpairment = '/cms/v1/global_settings/impairments.json',
}

export interface GetImpairmentsResponse {
  impairments: ImpairmentResponse[]
  pagination: PaginationResponse
  model_permissions: Permissions
}

export interface ImpairmentResponse extends Entity {
  id: string
  name: string
  clinical_name: string
  created_at: string
  updated_at: string
}

export type ImpairmentData = ImpairmentResponse

export const impairmentsDeserializer = (
  data: GetImpairmentsResponse,
): PaginatedItems<ImpairmentData> => ({
  ...data,
  items: data.impairments,
})

export const impairmentDeserializer = (
  data: ImpairmentResponse,
): ImpairmentData => {
  return {
    ...data,
  }
}

export interface GetSurvivorImpairmentsResponse {
  id: string
  created_at: string
  description: string
  first_noticed_date: string | null
  impairment: {
    id: string
    name: string
    clinical_name: string | null
  }
  resolved: boolean
  resolved_date: string | null
  updated_at: string
}

export interface GetSurvivorImpairmentsData extends Identifiable {
  id?: string
  impairment: string
  clinical_name?: string
  description: string
  impairment_id?: string
}

export const getSurvivorImpairmentDeserializer = (
  data: GetSurvivorImpairmentsResponse,
): GetSurvivorImpairmentsData => {
  return {
    clinical_name: data.impairment.clinical_name ?? '',
    description: data.description,
    id: data.id,
    impairment: data.impairment.name,
    impairment_id: data.impairment.id,
  }
}

export interface PaginatedSurvivorsImpairmentsResponse {
  impairments: GetSurvivorImpairmentsResponse[]
  pagination: PaginationResponse
}

export const getSurvivorImpairmentsDeserializer = (
  data: PaginatedSurvivorsImpairmentsResponse,
): PaginatedItems<GetSurvivorImpairmentsData> => ({
  items: data.impairments.map(getSurvivorImpairmentDeserializer),
  pagination: data.pagination,
})

export interface BasicImpairment extends Entity {
  id: string
  name: string
}
