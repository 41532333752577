import { transformPhoneNumber } from 'src/common/helpers'
import {
  CustomDate,
  dateDeserializer,
  DEFAULT_TIMEZONE,
  deserializeBasicFormattedDate,
  optionalDateDeserializer,
  Status,
  TabTypes,
} from 'src/common/types'
import { LearnTrackResponse } from 'src/components/LearnView/types'
import { CompletionStatusOptions } from 'src/pages/SurvivorView/Learn/types'
import { StrokeSubtype } from 'src/pages/SurvivorView/StrokeDetails/types'
import { TabNames } from 'src/pages/SurvivorView/types'
import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  fullNameDeserializer,
  getUserType,
  Languages,
  Model,
  PaginatedItems,
  PaginatedParams,
  PaginationResponse,
  permissionsDeserializer,
  tabPermissionDeserializer,
  UserType,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetSurvivors = '/cms/v1/mobile_survivors.json',
  GetSurvivor = '/cms/v1/mobile_survivors/%s',
  UpdateSurvivor = '/cms/v1/mobile_survivors/%s.json',
  NewSurvivor = '/cms/v1/mobile_survivors.json',
  DeleteSurvivor = '/cms/v1/mobile_survivors/%s.json',
  AssignCarePartner = '/cms/v1/mobile_survivors/%s/profile/mobile_caregivers/batch_assignment.json',
  GetSurvivorPlanOfCare = '/cms/v1/mobile_survivors/%s/profile/plan_of_care.json',
  UpdateSurvivorPlanOfCare = '/cms/v1/mobile_survivors/%s/profile/plan_of_care.json',
  GetSurvivorsAlerts = '/cms/v1/mobile_survivors/%s/alerts.json?page=%s',
  GetAlertsByTab = '/cms/v1/mobile_survivors/%s/alerts/alerts_by_tab.json',
  GetSurvivorsUnits = '/cms/v1/mobile_survivors/%s/lessons.json',
  ResetPassword = '/cms/v1/mobile_survivors/%s/account_management/reset_password.json',
  SendSignUpEmail = '/cms/v1/mobile_survivors/%s/account_management/send_sign_up_email.json',
  SendSignUpSMS = '/cms/v1/mobile_survivors/%s/account_management/send_sign_up_sms.json',
  SendAppInvitationEmail = '/cms/v1/mobile_survivors/%s/account_management/send_app_invitation_email.json',
  SendAppInvitationSMS = '/cms/v1/mobile_survivors/%s/account_management/send_app_invitation_sms.json',
  ChangeStatus = '/cms/v1/mobile_survivors/%s/account_management/change_status.json',
  NotifyUncompletedLesson = '/cms/v1/mobile_survivors/%s/lessons/notify_uncompleted_lessons.json',
  GetSurvivorsGroups = '/cms/v1/mobile_survivors/%s/groups.json',
  GetSurvivorsConversations = '/cms/v1/mobile_survivors/%s/navigator_conversations/%s.json',
  SendConversationMessage = '/cms/v1/mobile_survivors/%s/navigator_conversations/messages/new.json',
  EditConversationMessage = '/cms/v1/mobile_survivors/%s/navigator_conversations/messages/%s.json',
  DeleteConversationMessage = '/cms/v1/mobile_survivors/%s/navigator_conversations/messages/%s.json',
  SendWelcomeEmail = '/cms/v1/mobile_survivors/%s/account_management/welcome_mail.json',
  SendGraduationEmail = '/cms/v1/mobile_survivors/%s/account_management/graduation_mail.json',
}

export interface InfoSurvivorResponse extends Entity {
  id: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  alerts: boolean | null
  mobile_caregivers: User[]
  hospital?: {
    id: string
    name: string
  } // TODO: This is defined as optional by the moment because there are survivors w/o hospital. But in the future we have to set it as mandatory
  status: string
  post_acute_onboarding_date: string | null
  stroke_summary: string
  preferred_phone: string
  content_language: Languages
  cms_navigator: BaseUserResponse
}

export interface InfoSurvivorDataSource extends Entity {
  full_name: string
  email: string
  hospital?: {
    id: string
    name: string
  } // TODO: This is defined as optional by the moment because there are survivors w/o hospital. But in the future we have to set it as mandatory
  phone_number: string
  care_partner: string
  status: Status
  alerts: boolean | undefined
  survivor_link: string
  care_partner_link?: string
  key: string
  id: string
  care_partner_id?: string
  post_acute_onboarding_date?: string
  stroke_summary: string
  preferred_phone: string
  'hospitals.name'?: string
  content_language: Languages
  cms_navigator: BaseUser
  'cms_navigators.full_name'?: string
}

export interface GetSurvivorsResponse extends Model {
  mobile_survivors: InfoSurvivorResponse[]
  pagination: PaginationResponse
}

export interface Survivor extends Entity {
  tabs_permissions: TabTypes[]
  home_phone_preferred?: string
  phone_number_preferred?: string
  id: string
  address: string
  alerts: boolean
  full_name: string
  ambassador: boolean
  anatomical_location: string
  biography: string | undefined
  biological_sex: string
  brief_intro: string | undefined
  cms_clinician: {
    id: string
    last_name: string
    name: string
  }
  cms_navigator: {
    id: string
    last_name: string
    name: string
  }
  created_at: CustomDate
  date_of_birth: CustomDate
  discharge_date: CustomDate | undefined
  email: string
  emergency: string
  post_acute_registration_date: CustomDate | undefined
  ethnicity: string
  family_situation: string
  first_name: string
  gender_identity: string
  home_phone: string | undefined
  image: string
  primary_insurance_info: string
  last_name: string
  living_situation: string
  mobile_caregivers: MobileCarePartner[]
  mobile_user_id: string
  occurrence_date: CustomDate | undefined
  post_acute_onboarding_date: CustomDate | undefined
  hospital?: {
    id: string
    name: string
  } // TODO: This is defined as optional by the moment because there are survivors w/o hospital. But in the future we have to set it as mandatory
  patient_MRN: string
  phone_number: string | undefined
  preferred_phone: string
  preferred_name: string
  preferred_pronoun: string
  primary_physician_name: string
  race: string[]
  status: string
  stroke_story: string
  stroke_summary: string
  survivor_stroke_summary: string | undefined
  tacas_enabled: boolean
  test_user: boolean
  type_of_stroke: string
  updated_at: CustomDate
  city: string
  state: States
  zip_code: string
  welcome_mail_date: CustomDate | undefined
  graduation_mail_date: CustomDate | undefined
  time_zone: string | undefined
  learn_track: LearnTrackResponse
  stroke_subtype: StrokeSubtype | undefined
  content_language: Languages
}

export interface MobileCarePartner {
  id: string
  first_name: string
  last_name: string
  mobile_user_id: string
  full_name?: string
}

export const mobileCarePartnerDeserializer = (
  data: MobileCarePartner[],
): MobileCarePartner[] => {
  return data.map((carePartner: MobileCarePartner) => ({
    ...carePartner,
    full_name: fullNameDeserializer(carePartner),
  }))
}

export interface NewSurvivorResponse {
  mobile_survivor: GetSurvivorResponse
}

export interface GetSurvivorResponse extends Entity {
  tabs_permissions?: TabTypes[]
  hospital: {
    id: string
    name: string
  } | null
  time_zone: string | null
  id: string
  address: string
  alerts: boolean
  ambassador: boolean
  anatomical_location: string
  biography: string | null
  biological_sex: string
  brief_intro: string | null
  cms_clinician: {
    id: string
    last_name: string
    name: string
  }
  cms_navigator: {
    id: string
    last_name: string
    name: string
  }
  created_at: string
  date_of_birth: string
  discharge_date: string | null
  email: string
  emergency: string
  post_acute_registration_date: string | null
  ethnicity: string
  family_situation: string
  first_name: string
  gender_identity: string
  home_phone: string | null
  image: string
  primary_insurance_info: string
  last_name: string
  living_situation: string
  mobile_caregivers: MobileCarePartner[]
  mobile_user_id: string
  occurrence_date: string | null
  post_acute_onboarding_date: string | null
  originating_hospital_name: string
  patient_MRN: string
  phone_number: string | null
  preferred_name: string
  preferred_phone: string
  preferred_pronoun: string
  primary_physician_name: string
  race: string
  status: string
  stroke_story: string
  stroke_summary: string
  survivor_stroke_summary: string | null
  tacas_enabled: boolean
  test_user: boolean
  type_of_stroke: string
  updated_at: string
  city: string
  state: States
  zip_code: string
  welcome_mail_date: string | null
  graduation_mail_date: string | null
  learn_track: LearnTrackResponse
  stroke_subtype: StrokeSubtype | null
  content_language: Languages
}

export const getSurvivorDeserializer = (
  data: GetSurvivorResponse,
): Survivor => {
  return {
    address: data.address,
    alerts: data.alerts ?? false,
    ambassador: data.ambassador ?? false,
    anatomical_location: data.anatomical_location,
    biography: data.biography ?? undefined,
    biological_sex: data.biological_sex,
    brief_intro: data.brief_intro ?? undefined,
    city: data.city,
    cms_clinician: data.cms_clinician,
    cms_navigator: data.cms_navigator,
    content_language: data.content_language,
    created_at: dateDeserializer(data.created_at),
    date_of_birth: dateDeserializer(data.date_of_birth),
    discharge_date: optionalDateDeserializer(data.discharge_date),
    email: data.email,
    emergency: data.emergency,
    ethnicity: data.ethnicity,
    family_situation: data.family_situation,
    first_name: data.first_name,
    full_name: fullNameDeserializer(data),
    gender_identity: data.gender_identity,
    graduation_mail_date: optionalDateDeserializer(data.graduation_mail_date),
    home_phone: data.home_phone
      ? transformPhoneNumber(data.home_phone)
      : undefined,
    hospital: data.hospital ?? undefined,
    id: data.id,
    image: data.image,
    last_name: data.last_name,
    learn_track: data.learn_track,
    living_situation: data.living_situation,
    mobile_caregivers: mobileCarePartnerDeserializer(data.mobile_caregivers),
    mobile_user_id: data.mobile_user_id,
    occurrence_date: optionalDateDeserializer(data.occurrence_date),
    patient_MRN: data.patient_MRN,
    permissions: permissionsDeserializer(data.permissions),
    phone_number: data.phone_number
      ? transformPhoneNumber(data.phone_number)
      : undefined,
    post_acute_onboarding_date: optionalDateDeserializer(
      data.post_acute_onboarding_date,
    ),
    post_acute_registration_date: optionalDateDeserializer(
      data.post_acute_registration_date,
    ),
    preferred_name: data.preferred_name,
    preferred_phone: data.preferred_phone,
    preferred_pronoun: data.preferred_pronoun,
    primary_insurance_info: data.primary_insurance_info,
    primary_physician_name: data.primary_physician_name,
    race: data.race !== '' ? data.race.split(',') : [],
    state: data.state,
    status: data.status,
    stroke_story: data.stroke_story,
    stroke_subtype: data.stroke_subtype ?? undefined,
    stroke_summary: data.stroke_summary,
    survivor_stroke_summary: data.stroke_story ?? undefined,
    tabs_permissions: tabPermissionDeserializer(data),
    tacas_enabled: data.tacas_enabled ?? false,
    test_user: data.test_user ?? false,
    time_zone: data.time_zone ?? undefined,
    type_of_stroke: data.type_of_stroke,
    updated_at: dateDeserializer(data.updated_at),
    welcome_mail_date: optionalDateDeserializer(data.welcome_mail_date),
    zip_code: data.zip_code,
  }
}

export const infoSurvivorDeserializer = (
  data: InfoSurvivorResponse,
): InfoSurvivorDataSource => {
  const carePartner =
    data.mobile_caregivers?.length > 0 ? data.mobile_caregivers[0] : undefined

  return {
    alerts: data.alerts || false,
    care_partner: carePartner
      ? `${carePartner.first_name} ${carePartner.last_name}`
      : '',
    care_partner_id: carePartner ? carePartner.id : undefined,
    // TODO: Replace this for AppLink in the view and remove this attribute
    care_partner_link: `/care_partners/${carePartner?.id}`,
    cms_navigator: baseUserDeserializer(data.cms_navigator),
    'cms_navigators.full_name': data.cms_navigator.full_name,
    content_language: data.content_language,
    email: data.email,
    full_name: `${data.first_name} ${data.last_name}`,
    hospital: data.hospital,
    'hospitals.name': data.hospital?.name,
    id: data.id,
    key: data.id,
    permissions: permissionsDeserializer(data.permissions),
    phone_number: transformPhoneNumber(data.phone_number)!,
    post_acute_onboarding_date: data.post_acute_onboarding_date
      ? deserializeBasicFormattedDate(data.post_acute_onboarding_date)
      : undefined,
    preferred_phone: data.preferred_phone,
    status: data.status as Status,
    stroke_summary: data.stroke_summary,
    // TODO: Replace this for AppLink in the view and remove this attribute
    survivor_link: `/survivors/${data.id}`,
  }
}

export const survivorsDeserializer = (data: InfoSurvivorResponse[]) =>
  data.map(infoSurvivorDeserializer)

export const getSurvivorsDeserializer = (
  data: GetSurvivorsResponse,
): PaginatedItems<InfoSurvivorDataSource> => {
  return {
    items: survivorsDeserializer(data.mobile_survivors),
    model_permissions: data.model_permissions,
    pagination: data.pagination,
  }
}

export interface SurvivorPlanOfCareResponse {
  id: string
  created_at: Date
  disabilities: string | null
  disabilities_enabled: boolean
  education: string | null
  education_enabled: boolean
  employment: string | null
  employment_enabled: boolean
  family_and_community_support: string | null
  family_and_community_support_enabled: boolean
  financial_strain: string | null
  financial_strain_enabled: boolean
  food: string | null
  food_enabled: boolean
  legal_status: string | null
  legal_status_enabled: boolean
  living_situation: string | null
  living_situation_enabled: boolean
  mdp1: number | null
  mdp2: number | null
  mdp3: number | null
  mdp4: number | null
  mdp5: number | null
  mdp6: number | null
  mental_health: string | null
  mental_health_enabled: boolean
  mobile_survivor_id: string
  mrs_30_day: string | null
  mrs_7_day: string | null
  mrs_90_day: string | null
  mrs_baseline: string | null
  mrs_graduation: string | null
  nihss: number
  onboarding: string
  physical_activity: string | null
  physical_activity_enabled: boolean
  plan_of_care: string
  safety: string | null
  safety_enabled: boolean
  substance_abuse: string | null
  substance_abuse_enabled: boolean
  transportation: string | null
  transportation_enabled: boolean
  updated_at: Date
  utilities: string | null
  utilities_enabled: boolean
}

export interface SurvivorPlanOfCare {
  id: string
  created_at: Date
  disabilities?: string
  disabilities_enabled: boolean
  education?: string
  education_enabled: boolean
  employment?: string
  employment_enabled: boolean
  family_and_community_support?: string
  family_and_community_support_enabled: boolean
  financial_strain?: string
  financial_strain_enabled: boolean
  food?: string
  food_enabled: boolean
  legal_status?: string
  legal_status_enabled: boolean
  living_situation?: string
  living_situation_enabled: boolean
  mdp1?: number
  mdp2?: number
  mdp3?: number
  mdp4?: number
  mdp5?: number
  mdp6?: number
  mental_health?: string
  mental_health_enabled: boolean
  mobile_survivor_id: string
  mrs_30_day?: string
  mrs_7_day?: string
  mrs_90_day?: string
  mrs_baseline?: string
  mrs_graduation?: string
  nihss: number
  onboarding: string
  physical_activity?: string
  physical_activity_enabled: boolean
  plan_of_care: string
  safety?: string
  safety_enabled: boolean
  substance_abuse?: string
  substance_abuse_enabled: boolean
  transportation?: string
  transportation_enabled: boolean
  updated_at: Date
  utilities?: string
  utilities_enabled: boolean
}

export const survivorPlanOfCareDeserializer = (
  data: SurvivorPlanOfCareResponse,
): SurvivorPlanOfCare => {
  return {
    created_at: data.created_at,
    disabilities: data.disabilities ?? undefined,
    disabilities_enabled: data.disabilities_enabled,
    education: data.education ?? undefined,
    education_enabled: data.education_enabled,
    employment: data.employment ?? undefined,
    employment_enabled: data.employment_enabled,
    family_and_community_support:
      data.family_and_community_support ?? undefined,
    family_and_community_support_enabled:
      data.family_and_community_support_enabled,
    financial_strain: data.financial_strain ?? undefined,
    financial_strain_enabled: data.financial_strain_enabled,
    food: data.food ?? undefined,
    food_enabled: data.food_enabled,
    id: data.id,
    legal_status: data.legal_status ?? undefined,
    legal_status_enabled: data.legal_status_enabled,
    living_situation: data.living_situation ?? undefined,
    living_situation_enabled: data.living_situation_enabled,
    mdp1: data.mdp1 ?? undefined,
    mdp2: data.mdp2 ?? undefined,
    mdp3: data.mdp3 ?? undefined,
    mdp4: data.mdp4 ?? undefined,
    mdp5: data.mdp5 ?? undefined,
    mdp6: data.mdp6 ?? undefined,
    mental_health: data.mental_health ?? undefined,
    mental_health_enabled: data.mental_health_enabled,
    mobile_survivor_id: data.mobile_survivor_id,
    mrs_30_day: data.mrs_30_day ?? undefined,
    // eslint-disable-next-line sort-keys
    mrs_7_day: data.mrs_7_day ?? undefined,
    mrs_90_day: data.mrs_90_day ?? undefined,
    mrs_baseline: data.mrs_baseline ?? undefined,
    mrs_graduation: data.mrs_graduation ?? undefined,
    nihss: data.nihss,
    onboarding: data.onboarding,
    physical_activity: data.physical_activity ?? undefined,
    physical_activity_enabled: data.physical_activity_enabled,
    plan_of_care: data.plan_of_care,
    safety: data.safety ?? undefined,
    safety_enabled: data.safety_enabled,
    substance_abuse: data.substance_abuse ?? undefined,
    substance_abuse_enabled: data.substance_abuse_enabled,
    transportation: data.transportation ?? undefined,
    transportation_enabled: data.transportation_enabled,
    updated_at: data.updated_at,
    utilities: data.utilities ?? undefined,
    utilities_enabled: data.utilities_enabled,
  }
}

export interface SurvivorPlanOfCareRequest {
  body: {
    plan_of_care: Partial<SurvivorPlanOfCare>
  }
  id: string
}

export interface TabAlertResponse {
  tab: TabNameResponse
  priority: AlertPriority
}

export interface TabAlert {
  tab: TabNames
  priority: AlertPriority
}

export enum AlertPriority {
  High = 'high',
  Low = 'low',
}

export enum TabNameResponse {
  alerts = 'alerts',
  Assessments = 'assessments',
  GroupConversation = 'group-conversation',
  HealthEvent = 'health-event',
  Impairments = 'impairments',
  Learning = 'learning',
  PrivateConversation = 'private-conversation',
  QuickJots = 'quick-jots',
  ToDos = 'to-do-s',
}

export const TabMap = {
  [TabNameResponse.alerts]: TabNames.alerts,
  [TabNameResponse.Assessments]: TabNames.assessments,
  [TabNameResponse.GroupConversation]: TabNames.group_conversation,
  [TabNameResponse.Impairments]: TabNames.impairments,
  [TabNameResponse.Learning]: TabNames.learning,
  [TabNameResponse.PrivateConversation]: TabNames.private_conversation,
  [TabNameResponse.QuickJots]: TabNames.quick_jots,
  [TabNameResponse.ToDos]: TabNames.to_do_s,
  [TabNameResponse.HealthEvent]: TabNames.health_events,
}

export interface SurvivorAlertsResponse {
  id: string
  action: string
  alert: {
    module: string
    owner: BaseUserResponse
  }
  label: string
  created_at: string
  status: boolean
  triggered_by: BaseUserResponse
}

export interface SurvivorAlertsData {
  created_at: CustomDate
  alert: string
  label: string
  triggered_by: string
  user_type: string
  active: boolean
  owner: BaseUser
}

export const survivorAlertDeserializer = (
  data: SurvivorAlertsResponse,
): SurvivorAlertsData => {
  return {
    active: !data.status,
    alert: data.alert.module,
    created_at: dateDeserializer(data.created_at),
    label: data.label,
    owner: baseUserDeserializer(data.alert.owner),
    triggered_by: baseUserDeserializer(data?.triggered_by).full_name,
    user_type: getUserType(data.triggered_by.user_type),
  }
}

export interface PaginatedSurvivorAlertsResponse {
  alerts: SurvivorAlertsResponse[]
  pagination: PaginationResponse
}

export const survivorAlertsDeserializer = (
  data: PaginatedSurvivorAlertsResponse,
): PaginatedItems<SurvivorAlertsData> => ({
  items: data.alerts.map(survivorAlertDeserializer),
  pagination: data.pagination,
})

export const alertsDeserializer = (data: TabAlertResponse[]): TabAlert[] => {
  return data.map(
    (alert: TabAlertResponse): TabAlert => ({
      priority: alert.priority,
      tab: TabMap[alert.tab],
    }),
  )
}

export const childrenUnitsDeserializer = (data: UnitResponse[]): Unit[] =>
  data.map(
    (unit: UnitResponse): Unit => ({
      articles: unit.articles,
      children:
        (unit.children && childrenUnitsDeserializer(unit.children)) ||
        undefined,
      completed_date: optionalDateDeserializer(unit.completed_date),
      created_at: optionalDateDeserializer(unit.created_at),
      id: unit.id,
      title: unit.title,
    }),
  )

const articleInPackageDeserializer = (article: Article) => ({
  has_audio: article.has_audio,
  id: article.id,
  internal_id: article.internal_id,
  internal_name: article.internal_name,
  permissions: article.permissions,
  status: article.status,
  title: article.title,
})

export const lessonDeserializer = (lesson: MainUnitResponse): MainUnit => {
  return {
    articles: lesson.unit.articles.map(articleInPackageDeserializer),
    'articles.title': lesson.unit.articles.map(
      (article: Article) => article.title,
    ),
    children:
      lesson.unit.children && childrenUnitsDeserializer(lesson.unit.children),
    completed_date: optionalDateDeserializer(lesson.unit.completed_date),
    created_at: optionalDateDeserializer(lesson.created_at),
    deployment_date: dateDeserializer(
      lesson.deployment_date,
      lesson.mobile_user_timezone ?? DEFAULT_TIMEZONE,
    ),
    id: lesson.id,
    mobile_user_id: lesson.mobile_user.id,
    'mobile_user_lessons.created_at': optionalDateDeserializer(
      lesson.created_at,
    ),
    mobile_user_timezone: lesson.mobile_user_timezone,
    number_of_articles: lesson.unit.number_of_articles,
    number_of_articles_completed: lesson.unit.number_of_articles_completed,
    permissions: permissionsDeserializer(lesson.permissions),
    status: lesson.status as CompletionStatusOptions,
    title: lesson.unit.title,
    unit: lesson.unit,
    unit_id: lesson.unit.id,
    'units.completed_date': optionalDateDeserializer(
      lesson.unit.completed_date,
    ),
    'units.title': lesson.unit.title,
  }
}

export const unitsDeserializer = (
  data: GetUnitsResponse,
): PaginatedItems<MainUnit> => {
  return {
    items: data?.lessons?.map(lessonDeserializer),
    model_permissions: data.model_permissions,
    pagination: data.pagination,
  }
}
export interface GetUnitsResponse extends Model {
  lessons: MainUnitResponse[]
  pagination: PaginationResponse
}

export interface MainUnitResponse extends Entity {
  id: string
  created_at: string | null
  deployment_date: string
  mobile_user: {
    id: string
    full_name: string
    inheritor_id: string
    user_type: UserType
  }
  mobile_user_timezone?: string
  status: string
  unit: UnitResponse
  children: UnitResponse | null // INFO: Prop not used
  completed_date: string | null
}

export interface UnitResponse extends Entity {
  id: string
  articles: Article[]
  children: UnitResponse[] | null
  completed_date: string | null
  created_at: string | null
  number_of_articles: number
  number_of_articles_completed: number
  status: string
  title: string
}

export interface MainUnit extends Entity {
  id: string
  deployment_date: CustomDate
  status: CompletionStatusOptions
  completed_date?: CustomDate
  unit_id: string
  articles: Article[]
  created_at?: CustomDate
  children: Unit[] | null
  number_of_articles: number
  number_of_articles_completed: number
  title: string
  mobile_user_id: string
  unit: UnitResponse
  mobile_user_timezone?: string
  ['units.title']: string
  ['units.completed_date']?: CustomDate
  ['mobile_user_lessons.created_at']?: CustomDate
  ['articles.title']: string[]
}

export interface Unit {
  id: string
  articles: Article[]
  children?: Unit[]
  completed_date?: CustomDate
  title: string
  created_at?: CustomDate
}

export interface Article extends Entity {
  id: string
  title: string
  has_audio: boolean
  internal_id: string
  internal_name: string
  status: string
}

export interface GetNavigatorTasksResponse {
  navigator_tasks: NavigatorTaskResponse[]
  pagination: PaginationResponse
}

export interface NavigatorTaskResponse extends Entity {
  id: string
  category: string
  completed_date: string | null
  created_at: string
  description: string
  goal_date: string | null
  notes: string | null
  status: string
  time_spent: string | null
  type_of: string
  priority: number
}

export const arrayToQueryString = (
  paramName: string,
  array?: string[],
): string => {
  let result = ''
  if (array && array?.length > 0) {
    result = array?.reduce((acc, item) => `${acc}&${paramName}[]=${item}`, '')
  }
  return result
}

export interface User {
  id: string
  first_name: string
  last_name: string
}

export enum ConversationType {
  Private = 'private',
  Group = 'group',
}

export interface ConversationParams extends PaginatedParams {
  type: ConversationType
}

export interface GetConversationResponse {
  messages: ConversationResponse[]
  pagination: PaginationResponse
}

export interface Conversation {
  id: string
  content: string
  sent_at: CustomDate
  sent_by: BaseUserResponse
}

export interface ConversationResponse {
  id: string
  content: string
  sent_at: string
  sent_by: BaseUserResponse
}

export const getConversationDeserializer = (
  data: GetConversationResponse,
): PaginatedItems<Conversation> => {
  return {
    items: data.messages.map(
      (item: ConversationResponse): Conversation => ({
        content: item.content,
        id: item.id,
        sent_at: dateDeserializer(item.sent_at),
        sent_by: item.sent_by,
      }),
    ),
    pagination: data.pagination,
  }
}

export enum States {
  'Alabama' = 'AL',
  'Alaska' = 'AK',
  'Arizona' = 'AZ',
  'Arkansas' = 'AR',
  'California' = 'CA',
  'Colorado' = 'CO',
  'Connecticut' = 'CT',
  'Delaware' = 'DE',
  'Florida' = 'FL',
  'Georgia' = 'GA',
  'Hawaii' = 'HI',
  'Idaho' = 'ID',
  'Illinois' = 'IL',
  'Indiana' = 'IN',
  'Iowa' = 'IA',
  'Kansas' = 'KS',
  'Kentucky' = 'KY',
  'Louisiana' = 'LA',
  'Maine' = 'ME',
  'Maryland' = 'MD',
  'Massachusetts' = 'MA',
  'Michigan' = 'MI',
  'Minnesota' = 'MN',
  'Mississippi' = 'MS',
  'Missouri' = 'MO',
  'Montana' = 'MT',
  'Nebraska' = 'NE',
  'Nevada' = 'NV',
  'New Hampshire' = 'NH',
  'New Jersey' = 'NJ',
  'New Mexico' = 'NM',
  'New York' = 'NY',
  'North Carolina' = 'NC',
  'North Dakota' = 'ND',
  'Ohio' = 'OH',
  'Oklahoma' = 'OK',
  'Oregon' = 'OR',
  'Pennsylvania' = 'PA',
  'Rhode Island' = 'RI',
  'South Carolina' = 'SC',
  'South Dakota' = 'SD',
  'Tennessee' = 'TN',
  'Texas' = 'TX',
  'Utah' = 'UT',
  'Vermont' = 'VT',
  'Virginia' = 'VA',
  'Washington' = 'WA',
  'West Virginia' = 'WV',
  'Wisconsin' = 'WI',
  'Wyoming' = 'WY',
}
