import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addSuicidalityEmailButton: 'Add Email',
    createdAtColumnLabel: 'Created At',
    deleteModalTitle: 'Are you sure you want to delete this suicidality email?',
    emailColumnLabel: 'Email',
    suicidalityEmailDrawerTitle: 'Suicidality Email',
    title: 'Suicidality Emails',
    updatedAtColumnLabel: 'Updated At',
  },
})
