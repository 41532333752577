import React from 'react'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { DrawerWithStateProps } from 'src/components/DrawerWithState/types'
import {
  useAddImpairmentMutation,
  useDeleteImpairmentMutation,
  useLazyGetImpairmentQuery,
  useUpdateImpairmentMutation,
} from 'src/store/APIs/impairments'

import { AddImpairment } from '../AddImpairment'
import { strings } from '../strings'
import { ViewImpairment } from '../ViewImpairment'

interface ImpairmentsDrawerProps extends Partial<DrawerWithStateProps> {
  open: boolean
  onCloseDrawer: () => void
  selectedId?: string
}

export const ImpairmentsDrawer: React.FC<ImpairmentsDrawerProps> = ({
  open,
  onCloseDrawer,
  selectedId,
  ...props
}) => {
  const [triggerAddImpairmentMutation, { isLoading: loadingAdd }] =
    useAddImpairmentMutation()
  const [triggerEditImpairmentMutation, { isLoading: loadingEdit }] =
    useUpdateImpairmentMutation()
  const [triggerDeleteImpairmentMutation, { isLoading: loadingDelete }] =
    useDeleteImpairmentMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit

  return (
    <DrawerWithState
      selectedId={selectedId}
      open={open}
      resourceName={strings.impairmentDrawerTitle}
      RenderView={ViewImpairment}
      RenderAdd={AddImpairment}
      useLazyGetResource={useLazyGetImpairmentQuery}
      onCloseDrawer={onCloseDrawer}
      triggerDeleteResourceMutation={triggerDeleteImpairmentMutation}
      isLoadingLeftButton={loadingDelete}
      deleteModalTitle={strings.deleteModalTitle}
      triggerAddResourceMutation={triggerAddImpairmentMutation}
      triggerEditResourceMutation={triggerEditImpairmentMutation}
      isLoadingRightButton={isLoadingRightButton}
      {...props}
    />
  )
}
