import {
  CustomDate,
  deserializeBasicFormattedDate,
  optionalDateDeserializer,
} from 'src/common/types'

import { RetailProduct } from '../retailProducts/types'
import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  Model,
  PaginatedItems,
  PaginationResponse,
} from '../types'

export enum Endpoints {
  GetSurvivorsPurchaseTodos = '/cms/v1/mobile_survivors/%s/purchase_tasks.json',
  GetPurchaseTodo = '/cms/v1/purchase_tasks/%s.json',
  UpdatePurchaseTodo = '/cms/v1/purchase_tasks/%s.json',
  DeletePurchaseTodo = '/cms/v1/purchase_tasks/%s.json',
  AddPurchaseTodo = '/cms/v1/purchase_tasks.json',
  GetRetailProducts = '/cms/v1/global_settings/retail_products.json',
}

export interface SurvivorPurchaseTodoIndexResponse extends Entity {
  id: string
  completed: boolean
  completed_date: string | null
  description: string
  due_datetime: string | null
  retail_products: RetailProduct[]
}

export interface SurvivorPurchaseTodoIndexData extends Entity {
  id: string
  completed: boolean
  completed_date?: CustomDate
  todo: string
  due_datetime?: CustomDate
  retail_products: RetailProduct[]
}

export interface GetSurvivorPurchaseTodosResponse extends Model {
  purchase_tasks: SurvivorPurchaseTodoIndexResponse[]
  pagination: PaginationResponse
}

export interface SurvivorPurchaseTodoResponse extends Entity {
  id: string
  additional_details: string
  completed: boolean
  completed_by: BaseUserResponse | null
  completed_date: string | null
  created_at: string
  created_by: BaseUserResponse | null
  deleted_by: BaseUserResponse | null
  description: string
  discarded_at: string | null
  due_datetime: string | null
  mobile_survivor: BaseUserResponse
  retail_products: RetailProduct[]
  updated_at: string
}

export interface SurvivorPurchaseTodoData extends Entity {
  id: string
  description: string
  completed: boolean
  completed_by?: BaseUser
  completed_date?: CustomDate
  created_at: string
  created_by?: BaseUser
  deleted_by?: BaseUser
  discarded_at?: string
  due_datetime: CustomDate | undefined
  retail_products: RetailProduct[]
  todo: string
  updated_at: string
}

export const getSurvivorPurchaseTodoDeserializer = (
  data: SurvivorPurchaseTodoResponse,
): SurvivorPurchaseTodoData => {
  return {
    completed: data.completed,
    completed_by: data.completed_by
      ? baseUserDeserializer(data.completed_by)
      : undefined,
    completed_date: optionalDateDeserializer(data.completed_date),
    created_at: deserializeBasicFormattedDate(data.created_at),
    created_by: data.created_by
      ? baseUserDeserializer(data.created_by)
      : undefined,
    deleted_by: data.deleted_by
      ? baseUserDeserializer(data.deleted_by)
      : undefined,
    description: data.additional_details,
    discarded_at: data.discarded_at
      ? deserializeBasicFormattedDate(data.discarded_at)
      : undefined,
    due_datetime: optionalDateDeserializer(data.due_datetime),
    id: data.id,
    permissions: data.permissions,
    retail_products: data.retail_products,
    todo: data.description,
    updated_at: deserializeBasicFormattedDate(data.updated_at),
  }
}

export const getSurvivorPurchaseTodoIndexDeserializer = (
  data: SurvivorPurchaseTodoIndexResponse,
): SurvivorPurchaseTodoIndexData => {
  return {
    completed: data.completed,
    completed_date: optionalDateDeserializer(data.completed_date),
    due_datetime: optionalDateDeserializer(data.due_datetime),
    id: data.id,
    permissions: data.permissions,
    retail_products: data.retail_products,
    todo: data.description,
  }
}

export const getSurvivorPurchaseTodosDeserializer = (
  data: GetSurvivorPurchaseTodosResponse,
): PaginatedItems<SurvivorPurchaseTodoIndexData> => ({
  items: data.purchase_tasks.map(getSurvivorPurchaseTodoIndexDeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})

export interface AddPurchaseTodo {
  todo: string
  description?: string
  retail_product_ids: string[]
  mobile_survivor_id: string
  due_datetime?: string
}
