import { Alert, Divider, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { DrawerRenderProps } from 'src/components/DrawerWithState/types'
import {
  richTextDefaultText,
  RichTextEditor,
} from 'src/components/RichTextEditor'
import { SearchSelect } from 'src/components/SearchSelect'
import { Select } from 'src/components/Select'
import { TextInput } from 'src/components/TextInput'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import { useAppDispatch } from 'src/store'
import {
  articlesApi,
  useGenerateSsmlMutation,
  usePaginateContentMutation,
} from 'src/store/APIs/articles'
import {
  ArticleMode,
  ExtendedArticleInfo,
  SuggestedQuestion,
  SuggestedTodo,
} from 'src/store/APIs/articles/types'
import { useLazyGetRetailProductsQuery } from 'src/store/APIs/retailProducts'
import { languageOptions, LearnStatus } from 'src/store/APIs/types'
import {
  clearAddArticleErrorAction,
  clearAudioErrorAction,
} from 'src/store/storage/error'
import {
  selectAddArticleError,
  selectAudioError,
} from 'src/store/storage/error/selectors'

import { useInternalIdValidation } from '../../hooks/useInternalIdValidation'
import { audienceOptions, redirectLinksOptions, themeOptions } from '../types'
import styles from './addArticle.module.scss'
import { ContentEdition } from './ContentEdition'
import { strings } from './strings'
import { SsmlError } from './types'
import { requiredValidator, yupSync } from './validations'

interface AddArticleProps extends DrawerRenderProps {
  data?: ExtendedArticleInfo
}

const initialQuestion: Partial<SuggestedQuestion> = {
  question: '',
}

const initialTodo: Partial<SuggestedTodo> = {
  retail_products: undefined,
  todo: '',
}

export const AddArticle: React.FC<AddArticleProps> = ({
  setCanSubmitForm,
  setCanSaveDraft,
  data,
}) => {
  const {
    optionsSelect: retailProductsOptions,
    fetchedFilteredElems: fetchedFilteredRetailProducts,
  } = useSearchOptionsSelect(useLazyGetRetailProductsQuery, undefined, 'name')

  const { clearAddError: clearAddArticleError } = useInternalIdValidation(
    clearAddArticleErrorAction,
    selectAddArticleError,
  )

  const audioError = useSelector(selectAudioError)

  const [contentMode, setContentMode] = useState<ArticleMode>(
    data ? data.mode : ArticleMode.Content,
  )

  const isChild = !!data?.assigned_to

  useEffect(() => {
    if (data && data.status !== LearnStatus.draft) {
      setCanSaveDraft?.(false)
    } else {
      setCanSaveDraft?.(true)
    }
  }, [])

  useEffect(() => {
    setCanSubmitForm?.(contentMode !== ArticleMode.Content)
  }, [contentMode])

  const [
    triggerPaginateContentMutation,
    {
      data: paginatedContent,
      isSuccess: paginationSuccess,
      reset: resetPaginateMutation,
      isLoading: paginationLoading,
    },
  ] = usePaginateContentMutation()

  const [
    triggerGenerateSsmlMutation,
    {
      data: ssmlContent,
      isSuccess: ssmlSuccess,
      reset: resetSsmlMutation,
      isLoading: ssmlLoading,
    },
  ] = useGenerateSsmlMutation()

  const isLoading = paginationLoading || ssmlLoading

  const form = Form.useFormInstance()

  const dispatch = useAppDispatch()

  const resetContent = () => {
    form.setFieldValue('content', richTextDefaultText)
    form.setFieldValue('pages_attributes', undefined)
    form.setFieldValue('pages_attributes_edited', undefined)
    resetPaginateMutation()
    resetSsmlMutation()
    setContentMode(ArticleMode.Content)

    if (data) {
      dispatch(
        articlesApi.util.updateQueryData(
          'getArticle',
          { id: data.id },
          draft => {
            const cleanObject = {
              audio: null,
              content: null,
              number_of_pages: 0,
              pages_attributes: [],
              pages_attributes_edited: [],
            }
            Object.assign(draft, cleanObject)
          },
        ),
      )
    }
  }

  useEffect(() => {
    if (ssmlSuccess) {
      setContentMode(ArticleMode.Ssml)
    } else if (paginationSuccess) {
      setContentMode(ArticleMode.Paginated)
    }
  }, [paginationSuccess, ssmlSuccess])

  useEffect(() => {
    dispatch(clearAudioErrorAction())
    return () => {
      dispatch(clearAudioErrorAction())
    }
  }, [])

  return (
    <div>
      {!!audioError && (
        <Alert
          type="error"
          banner
          message={(audioError as SsmlError)?.data?.errors}
          closable
          className={styles.banner}
        />
      )}
      <Form.Item name="title" rules={[yupSync]}>
        <TextInput label={strings.titleLabel} required />
      </Form.Item>
      <Form.Item name="internal_name" rules={[yupSync]}>
        <TextInput label={strings.internalNameLabel} required />
      </Form.Item>
      <Row className={styles.row}>
        <Form.Item
          name="audience"
          rules={[yupSync]}
          className={styles.rowInput}>
          <Select
            label={strings.audienceLabel}
            options={audienceOptions}
            placeholder={strings.selectPlaceholder}
            required
          />
        </Form.Item>
        <Form.Item
          name="internal_id"
          rules={[yupSync]}
          className={styles.rowInput}>
          <TextInput
            label={strings.internalIdLabel}
            required
            onFocus={clearAddArticleError}
          />
        </Form.Item>
        <Form.Item name="theme" rules={[yupSync]} className={styles.rowInput}>
          <Select
            label={strings.themeLabel}
            options={themeOptions}
            placeholder={strings.selectPlaceholder}
            required
          />
        </Form.Item>
      </Row>
      <Row className={styles.row3}>
        <Form.Item name="language" rules={[yupSync]}>
          <Select
            disabled={isChild}
            options={languageOptions}
            placeholder={strings.selectPlaceholder}
            label={strings.languageLabel}
            required
          />
        </Form.Item>
        <Form.Item name="link" rules={[yupSync]}>
          <Select
            label={strings.redirectLinkLabel}
            options={redirectLinksOptions}
            placeholder={strings.selectPlaceholder}
            optionalLabel
            allowClear
          />
        </Form.Item>
      </Row>
      <Form.Item name="revision_history" rules={[yupSync]}>
        <RichTextEditor label={strings.revisionHistoryLabel} />
      </Form.Item>
      <Form.Item initialValue name="enable_feedback" valuePropName="checked">
        <Checkbox>{strings.enableFeedbackLabel}</Checkbox>
      </Form.Item>
      <ContentEdition
        mode={contentMode}
        triggerPaginateContentMutation={triggerPaginateContentMutation}
        triggerGenerateSsmlMutation={triggerGenerateSsmlMutation}
        initialPages={ssmlContent?.pages || paginatedContent?.pages}
        resetContent={resetContent}
        isLoading={isLoading}
        article={data}
      />
      <Divider className={styles.divider} />
      <div className={styles.titleRow}>
        <span className={styles.title}>{strings.suggestedQuestions}</span>
        <span className="optional-label">{strings.optionalLabel}</span>
      </div>
      <Form.List name="suggested_questions">
        {(suggested_questions, { add, remove }) => (
          <>
            <Button
              type="default"
              className={styles.addButton}
              onClick={() => {
                add(initialQuestion)
              }}>
              {strings.addSuggestedQuestionButtonLabel}
            </Button>
            {suggested_questions.map((question, index) => (
              <div key={question.name}>
                <Form.Item
                  name={[question.name, 'question']}
                  rules={[
                    {
                      validator: requiredValidator,
                    },
                  ]}>
                  <TextInput
                    label={strings.questionLabel}
                    placeholder={strings.questionPlaceholder}
                    required
                    autoFocus={
                      !form.getFieldValue('suggested_questions')[question.name]
                        .question
                    }
                  />
                </Form.Item>
                <Button type="dashed" danger onClick={() => remove(index)}>
                  {strings.removeButtonLabel}
                </Button>
                {suggested_questions.length !== index + 1 && (
                  <Divider dashed className={styles.divider} />
                )}
              </div>
            ))}
          </>
        )}
      </Form.List>
      <Divider className={styles.divider} />
      <div className={styles.titleRow}>
        <span className={styles.title}>{strings.suggestedTodos}</span>
        <span className="optional-label">{strings.optionalLabel}</span>
      </div>
      <Form.List name="suggested_todos">
        {(suggested_todos, { add, remove }) => (
          <>
            <Button
              type="default"
              className={styles.addButton}
              onClick={() => {
                add(initialTodo)
              }}>
              {strings.addSuggestedTodoButtonLabel}
            </Button>
            {suggested_todos.map((todo, index) => {
              const initialValueRetailProducts =
                data?.suggested_todos.length !== 0
                  ? data?.suggested_todos[index]?.retail_products?.map(
                      item => item.id,
                    )
                  : undefined
              return (
                <div key={todo.name}>
                  <Form.Item
                    name={[todo.name, 'todo']}
                    rules={[
                      {
                        validator: requiredValidator,
                      },
                    ]}>
                    <TextInput
                      label={strings.todoLabel}
                      placeholder={strings.todoPlaceholder}
                      required
                      autoFocus={
                        !form.getFieldValue('suggested_todos')[todo.name].todo
                      }
                    />
                  </Form.Item>
                  {retailProductsOptions && (
                    <Form.Item
                      name={[todo.name, 'retail_product_ids']}
                      initialValue={initialValueRetailProducts}>
                      <SearchSelect
                        mode="multiple"
                        withDescription
                        options={retailProductsOptions}
                        fetchOptions={fetchedFilteredRetailProducts}
                        optionalLabel
                        label={strings.retailProductsLabel}
                      />
                    </Form.Item>
                  )}
                  <Button type="dashed" danger onClick={() => remove(index)}>
                    {strings.removeButtonLabel}
                  </Button>
                  {suggested_todos.length !== index + 1 && (
                    <Divider dashed className={styles.divider} />
                  )}
                </div>
              )
            })}
          </>
        )}
      </Form.List>
      <Form.Item name="status" hidden />
    </div>
  )
}
