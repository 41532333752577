import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addRetailCategoryButton: 'Add Retail Category',
    addRetailProductButton: 'Add Retail Product',
    deleteCategoryModalTitle:
      'Are you sure you want to delete this retail product category?',
    deleteProductModalTitle:
      'Are you sure you want to delete this retail product?',
    descriptionColumnLabel: 'Description',
    impairmentsColumnLabel: 'Impairments',
    itemDescriptionColumnLabel: 'Item Description',
    linkColumnLabel: 'Link',
    linkTextInput: 'Link URL',
    nameColumnLabel: 'Name',
    needsColumnLabel: 'Needs',
    notesColumnLabel: 'Notes',
    resourceDescriptionColumnLabel: 'Resource Description',
    retailCategoriesColumnLabel: 'Retail Categories',
    retailCategoryDrawerTitle: 'Retail Product Category',
    retailProductTitle: 'Retail Product',
    title: 'Retail Products',
    updatedAtColumnLabel: 'Updated At',
    urlValidation: 'Enter a valid URL',
  },
})
