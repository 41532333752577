/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react'
import { mergeArrayInPlace } from 'src/common/helpers'
import { DrawerRenderProps } from 'src/components/DrawerWithState/types'
import { InfoTable } from 'src/components/InfoTable'
import { Spinner } from 'src/components/Spinner'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { useLazyGetTacasQuery } from 'src/store/APIs/tacas'

import { strings } from '../strings'
import styles from '../tacas.module.scss'

interface ViewTacasProps extends DrawerRenderProps {
  tacasId?: string
}

export const ViewTacas: React.FC<ViewTacasProps> = ({ tacasId }) => {
  const [triggerGetTacas, { data, isLoading }] = useLazyGetTacasQuery()

  useEffect(() => {
    if (tacasId) triggerGetTacas({ id: tacasId })
  }, [tacasId])

  const rows = [
    {
      content: data?.version,
      label: strings.versionLabel,
    },
    {
      content: data?.completed_by ? (
        <UserHyperlink user={data.completed_by} />
      ) : undefined,
      label: strings.completedByLabel,
    },
    {
      content: data?.created_at,
      label: strings.createdAtLabel,
    },
  ]

  if (data?.basic_questions) {
    const basicQuestions = data?.basic_questions.map(item => ({
      content: item.content,
      label: item.title,
    }))
    mergeArrayInPlace(rows, basicQuestions, 1)
  }

  if (!data || isLoading) return <Spinner />
  return (
    <>
      <InfoTable rows={rows} contentStyle={{ width: '40%' }} />
      {data.extra_questions.map(item => (
        <InfoTable
          className={styles.table}
          title={item.title}
          rows={[
            { content: item.content, label: strings.responseLabel },
            {
              content: item.image ? (
                <img alt={strings.imageLabel} src={item.image} width={150} />
              ) : undefined,
              label: strings.imageLabel,
            },
            {
              content: item.audio ? (
                <audio controls src={item.audio} preload="auto">
                  {strings.audioLabel}
                </audio>
              ) : undefined,
              label: strings.audioLabel,
            },
          ]}
        />
      ))}
    </>
  )
}
