import { baseApi } from 'src/store/APIs'

import {
  addRetailCategory,
  addRetailProduct,
  deleteRetailCategory,
  deleteRetailProduct,
  editRetailCategory,
  editRetailProduct,
  getRetailCategories,
  getRetailCategory,
  getRetailProduct,
  getRetailProducts,
} from './actions'

export const retailProductsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addRetailCategory: addRetailCategory(builder),
    addRetailProduct: addRetailProduct(builder),
    deleteRetailCategory: deleteRetailCategory(builder),
    deleteRetailProduct: deleteRetailProduct(builder),
    editRetailCategory: editRetailCategory(builder),
    editRetailProduct: editRetailProduct(builder),
    getRetailCategories: getRetailCategories(builder),
    getRetailCategory: getRetailCategory(builder),
    getRetailProduct: getRetailProduct(builder),
    getRetailProducts: getRetailProducts(builder),
  }),
})

export const {
  useGetRetailProductsQuery,
  useLazyGetRetailProductsQuery,
  useGetRetailCategoriesQuery,
  useLazyGetRetailCategoryQuery,
  useAddRetailCategoryMutation,
  useEditRetailCategoryMutation,
  useDeleteRetailCategoryMutation,
  useLazyGetRetailProductQuery,
  useAddRetailProductMutation,
  useLazyGetRetailCategoriesQuery,
  useEditRetailProductMutation,
  useDeleteRetailProductMutation,
} = retailProductsApi
