import React from 'react'
import { Button } from 'src/components/Button'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import { useLazyGetImpairmentsQuery } from 'src/store/APIs/impairments'

import styles from '../survivorProfile.module.scss'
import { columns } from './columns'
import { ImpairmentsDrawer } from './ImpairmentsDrawer'
import { strings } from './strings'

export const Impairments: React.FC = () => {
  const { open, onCloseDrawer, openView, selectedId, showDrawer } = useDrawer()

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.impairmentsTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addImpairmentButton}
        </Button>
      </div>
      <ImpairmentsDrawer
        open={open}
        selectedId={selectedId}
        onCloseDrawer={onCloseDrawer}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetImpairmentsQuery}
      />
    </div>
  )
}
