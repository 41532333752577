import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  provideListToTag,
  Tags,
} from '../types'
import {
  Endpoints,
  SuicidalityEmail,
  suicidalityEmailsDeserializer,
} from './types'

export const getSuicidalityEmails = (builder: Builder) =>
  builder.query<PaginatedItems<SuicidalityEmail>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.SuicidalityEmails),
    query: endpointWithFilters(Endpoints.GetSuicidalityEmails),
    transformResponse: suicidalityEmailsDeserializer,
  })
