import { Form } from 'antd'
import React from 'react'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { SearchSelect } from 'src/components/SearchSelect'
import { Spinner } from 'src/components/Spinner'
import { TextInput } from 'src/components/TextInput'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import { useLazyGetImpairmentsQuery } from 'src/store/APIs/impairments'
import { useLazyGetRetailCategoriesQuery } from 'src/store/APIs/retailProducts'
import { RetailProductExtended } from 'src/store/APIs/retailProducts/types'

import { strings } from '../strings'
import { yupSync } from './validations'

interface AddRetailProductProps {
  data?: RetailProductExtended
}

export const AddRetailProduct: React.FC<AddRetailProductProps> = ({ data }) => {
  const {
    optionsSelect: retailCategoriesOptions,
    fetchedFilteredElems: fetchedFilteredCategories,
    isLoading: isLoadingCategory,
  } = useSearchOptionsSelect(useLazyGetRetailCategoriesQuery, undefined, 'name')

  const {
    optionsSelect: impairmentsOptions,
    fetchedFilteredElems: fetchedFilteredImpairments,
    isLoading: isLoadingImpairment,
  } = useSearchOptionsSelect(useLazyGetImpairmentsQuery, undefined, 'name')

  if (isLoadingCategory || isLoadingImpairment) return <Spinner />

  return (
    <>
      <Form.Item name="name" rules={[yupSync]}>
        <TextInput label={strings.nameColumnLabel} required />
      </Form.Item>
      <Form.Item name="item_description" rules={[yupSync]}>
        <MultilineTextInput label={strings.itemDescriptionColumnLabel} />
      </Form.Item>
      <Form.Item name="link" rules={[yupSync]}>
        <TextInput label={strings.linkTextInput} required />
      </Form.Item>
      <Form.Item name="notes" rules={[yupSync]}>
        <MultilineTextInput label={strings.notesColumnLabel} />
      </Form.Item>
      <Form.Item name="resource_description" rules={[yupSync]}>
        <MultilineTextInput label={strings.resourceDescriptionColumnLabel} />
      </Form.Item>
      <Form.Item name="needs" rules={[yupSync]}>
        <TextInput label={strings.needsColumnLabel} />
      </Form.Item>
      {retailCategoriesOptions && (
        <Form.Item
          name="retail_category_ids"
          rules={[yupSync]}
          initialValue={data?.retail_categories?.map(item => item.id)}>
          <SearchSelect
            mode="multiple"
            options={retailCategoriesOptions}
            label={strings.retailCategoriesColumnLabel}
            placeholder={strings.retailCategoriesColumnLabel}
            fetchOptions={fetchedFilteredCategories}
          />
        </Form.Item>
      )}
      {impairmentsOptions && (
        <Form.Item
          name="impairment_ids"
          rules={[yupSync]}
          initialValue={data?.impairments?.map(item => item.id)}>
          <SearchSelect
            mode="multiple"
            options={impairmentsOptions}
            label={strings.impairmentsColumnLabel}
            placeholder={strings.impairmentsColumnLabel}
            fetchOptions={fetchedFilteredImpairments}
          />
        </Form.Item>
      )}
    </>
  )
}
