import { LeftOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import React from 'react'
import { Button } from 'src/components/Button'
import { InfoTable } from 'src/components/InfoTable'
import { useDrawer } from 'src/hooks/useDrawer'
import { ImpairmentsDrawer } from 'src/pages/SurvivorProfile/Impairments/ImpairmentsDrawer'
import { RetailProductExtended } from 'src/store/APIs/retailProducts/types'

import { RetailCategoriesDrawer } from '../RetailCategoriesDrawer'
import styles from '../retailProducts.module.scss'
import { strings } from '../strings'

interface ViewRetailProductProps {
  data?: RetailProductExtended
}

export const ViewRetailProduct: React.FC<ViewRetailProductProps> = ({
  data,
}) => {
  const {
    open: openImpairment,
    onCloseDrawer: onCloseImpairmentDrawer,
    selectedId: selectedImpairmentId,
    openView: openViewImpairment,
  } = useDrawer()

  const {
    open: openCategory,
    onCloseDrawer: onCloseCategoryDrawer,
    selectedId: selectedCategoryId,
    openView: openViewCategory,
  } = useDrawer()

  const rows = [
    {
      content: data?.name,
      label: strings.nameColumnLabel,
    },
    {
      content: data?.item_description ? data?.item_description : undefined,
      label: strings.itemDescriptionColumnLabel,
    },
    {
      content: data?.link ? (
        <a
          href={
            data?.link?.startsWith('http') || data?.link?.startsWith('https')
              ? data.link
              : `https://${data.link}`
          }
          target="_blank"
          rel="noopener noreferrer">
          {data?.link}
        </a>
      ) : undefined,
      label: strings.linkColumnLabel,
    },
    {
      content: data?.notes ? data?.notes : undefined,
      label: strings.notesColumnLabel,
    },
    {
      content: data?.resource_description
        ? data?.resource_description
        : undefined,
      label: strings.resourceDescriptionColumnLabel,
    },
    {
      content: data?.needs ? data?.needs : undefined,
      label: strings.needsColumnLabel,
    },
    {
      content:
        data?.retail_categories && data?.retail_categories.length > 0 ? (
          <Row>
            {data?.retail_categories.map((category, index) => (
              <Button
                key={category.id}
                className={styles.link}
                type="link"
                onClick={openViewCategory(category.id)}>
                {category.name}
                {index !== data.retail_categories!.length - 1 && ', '}
              </Button>
            ))}
          </Row>
        ) : undefined,
      label: strings.retailCategoriesColumnLabel,
    },
    {
      content:
        data?.impairments && data?.impairments.length > 0 ? (
          <Row>
            {data?.impairments.map((impairment, index) => (
              <Button
                key={impairment.id}
                className={styles.link}
                type="link"
                onClick={openViewImpairment(impairment.id)}>
                {impairment.name}
                {index !== data.impairments!.length - 1 && ', '}
              </Button>
            ))}
          </Row>
        ) : undefined,
      label: strings.impairmentsColumnLabel,
    },
  ]

  return (
    <>
      <RetailCategoriesDrawer
        open={openCategory}
        selectedId={selectedCategoryId}
        onCloseDrawer={onCloseCategoryDrawer}
        closeIcon={<LeftOutlined />}
      />
      <ImpairmentsDrawer
        open={openImpairment}
        selectedId={selectedImpairmentId}
        onCloseDrawer={onCloseImpairmentDrawer}
        closeIcon={<LeftOutlined />}
      />
      <InfoTable rows={rows} />
    </>
  )
}
