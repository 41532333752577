import { Form } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React from 'react'
import { useParams } from 'react-router-dom'
import { DatePicker } from 'src/components/DatePicker'
import { RichTextEditor } from 'src/components/RichTextEditor'
import { SearchSelect } from 'src/components/SearchSelect'
import { Spinner } from 'src/components/Spinner'
import { TextInput } from 'src/components/TextInput'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import { SurvivorPurchaseTodoData } from 'src/store/APIs/purchaseTodos/types'
import { useLazyGetRetailProductsQuery } from 'src/store/APIs/retailProducts'

import { strings } from '../strings'
import { yupSync } from './validations'

interface AddPurchaseTodoProps {
  data?: SurvivorPurchaseTodoData
}

export const AddPurchaseTodo: React.FC<AddPurchaseTodoProps> = ({ data }) => {
  const { id } = useParams<{ id: string }>()

  const {
    optionsSelect: retailProductsOptions,
    fetchedFilteredElems: fetchedFilteredRetailProducts,
  } = useSearchOptionsSelect(
    useLazyGetRetailProductsQuery,
    data?.retail_products,
    'name',
  )

  if (!retailProductsOptions) return <Spinner />
  return (
    <>
      <Form.Item name="todo" rules={[yupSync]}>
        <TextInput label={strings.todoLabel} required />
      </Form.Item>
      <Form.Item name="description" rules={[yupSync]}>
        <RichTextEditor label={strings.descriptionLabelOptional} />
      </Form.Item>
      {retailProductsOptions && (
        <FormItem
          name="retail_product_ids"
          rules={[yupSync]}
          initialValue={data?.retail_products.map(item => item.id)}>
          <SearchSelect
            mode="multiple"
            withDescription
            options={retailProductsOptions}
            fetchOptions={fetchedFilteredRetailProducts}
            placeholder={strings.retailProductsColumnLabel}
            required
            label={strings.retailProductsColumnLabel}
          />
        </FormItem>
      )}
      <Form.Item name="due_datetime">
        <DatePicker
          label={strings.reminderDateLabel}
          format="MM-DD-YYYY hh:mm A"
          showTime={{ use12Hours: true }}
          subtitle={strings.reminderDateSubtitle}
        />
      </Form.Item>
      <Form.Item name="mobile_survivor_id" hidden initialValue={id} />
    </>
  )
}
