import { Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { Button } from 'src/components/Button'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { ImpairmentResponse } from 'src/store/APIs/impairments/types'
import {
  RetailCategory,
  RetailProduct,
} from 'src/store/APIs/retailProducts/types'
import { PaginatedItems } from 'src/store/APIs/types'

import styles from './retailProducts.module.scss'
import { strings } from './strings'
import { RetailProductsDataIndex } from './types'

export const useRetailProductsColumns = (
  impairmentsData: PaginatedItems<ImpairmentResponse> | undefined,
  retailCategoriesData: PaginatedItems<RetailCategory> | undefined,
  openCategoryView?: (id: string) => () => void,
  openProductView?: (id: string) => () => void,
  openImpairmentView?: (id: string) => () => void,
) => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const impairmentsFilters = impairmentsData?.items?.map(
    (impairment: { id: string; name: string }) => ({
      text: impairment.name,
      value: impairment.name,
    }),
  )

  const retailCategoriesFilters = retailCategoriesData?.items?.map(
    (retail_category: { id: string; name: string }) => ({
      text: retail_category.name,
      value: retail_category.name,
    }),
  )

  const columns: ColumnsType<RetailProduct> = [
    {
      ...generateColumnTitle(
        RetailProductsDataIndex.name,
        strings.nameColumnLabel,
      ),
      ...generateColumnSearchProps(RetailProductsDataIndex.name),
      filteredValue: filteredInfo.filters?.name || null,
      render: (title, record) => {
        return openProductView ? (
          <Button type="link" onClick={openProductView(record.id)}>
            {title}
          </Button>
        ) : (
          title
        )
      },
      sorter: true,
      width: 300,
    },
    {
      ...generateColumnTitle(
        RetailProductsDataIndex.item_description,
        strings.descriptionColumnLabel,
      ),
      ...generateColumnSearchProps(RetailProductsDataIndex.item_description),
      filteredValue: filteredInfo.filters?.item_description || null,
      sorter: true,
      width: 300,
    },
    {
      ...generateColumnTitle(
        RetailProductsDataIndex['retail_categories.name'],
        strings.retailCategoriesColumnLabel,
      ),
      filteredValue: filteredInfo.filters?.['retail_categories.name'] || null,
      filters: retailCategoriesFilters,
      render: (_, record) => {
        const categories = record.retail_categories
        return (
          <Row>
            {categories?.map((category, index) => {
              return (
                <Button
                  key={category.id}
                  className={styles.link}
                  type="link"
                  onClick={openCategoryView?.(category.id)}>
                  {category.name}
                  {index !== categories.length - 1 && ', '}
                </Button>
              )
            })}
          </Row>
        )
      },
      sorter: true,
      width: 300,
    },
    {
      ...generateColumnTitle(
        RetailProductsDataIndex['impairments.name'],
        strings.impairmentsColumnLabel,
      ),
      filteredValue: filteredInfo.filters?.['impairments.name'] || null,
      filters: impairmentsFilters,
      render: (_, record) => {
        const { impairments } = record
        return (
          <Row>
            {impairments?.map((impairment, index) => {
              return (
                <Button
                  key={impairment.id}
                  className={styles.link}
                  type="link"
                  onClick={openImpairmentView?.(impairment.id)}>
                  {impairment.name}
                  {index !== impairments.length - 1 && ', '}
                </Button>
              )
            })}
          </Row>
        )
      },
      sorter: true,
      width: 300,
    },
    {
      ...generateColumnTitle(
        RetailProductsDataIndex.updated_at,
        strings.updatedAtColumnLabel,
      ),
      render: value => deserializeShortFormattedDate(value),
      width: 150,
    },
  ]

  return {
    columns,
    filteredInfo,
    ...restFilteredInfo,
  }
}
