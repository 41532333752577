import {
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit'
import { message } from 'antd'
import { useDispatch } from 'react-redux'
import { generalStrings } from 'src/common/generalStrings'
import { capitalizeString, removeUnderscores } from 'src/common/helpers'
import { ErrorStatus } from 'src/store/helpers'

import { baseApi } from './APIs'
import { authApi } from './APIs/auth'
import { carePartnersApi } from './APIs/carePartners'
import { communityGroupsApi } from './APIs/communityGroups'
import { healthcareTeamApi } from './APIs/healthcareTeam'
import { healthConditionsApi } from './APIs/healthConditions'
import { healthEventsApi } from './APIs/healthEvents'
import { hospitalsApi } from './APIs/hospitals'
import { hospitalTreatmentsApi } from './APIs/hospitalTreatments'
import { impairmentsApi } from './APIs/impairments'
import { miscApi } from './APIs/misc'
import { mobileUsersApi } from './APIs/mobileUsers'
import { navigatorsApi } from './APIs/navigators'
import { otherEventsApi } from './APIs/otherEvents'
import { retailProductsApi } from './APIs/retailProducts'
import { strokeCausesApi } from './APIs/strokeCauses'
import { suicidalityEmailsApi } from './APIs/suicidalityEmails'
import { survivorsApi } from './APIs/survivors'
import { usersApi } from './APIs/users'
import { rootReducer } from './rootReducer'
import { setErrorAction } from './storage/error'

const middlewares: Middleware[] = [
  authApi.middleware,
  survivorsApi.middleware,
  hospitalTreatmentsApi.middleware,
  otherEventsApi.middleware,
  strokeCausesApi.middleware,
  healthcareTeamApi.middleware,
  impairmentsApi.middleware,
  healthEventsApi.middleware,
  healthConditionsApi.middleware,
  carePartnersApi.middleware,
  communityGroupsApi.middleware,
  navigatorsApi.middleware,
  mobileUsersApi.middleware,
  miscApi.middleware,
  baseApi.middleware,
  hospitalsApi.middleware,
  usersApi.middleware,
  retailProductsApi.middleware,
  suicidalityEmailsApi.middleware,
]

const generateErrorMessage = (payloadData: any): string => {
  let errorMessage = ''
  if (payloadData?.error) {
    errorMessage = payloadData.error
  } else if (payloadData?.errors) {
    if (typeof payloadData.errors === 'object') {
      Object.keys(payloadData.errors).forEach((erroredField: string) => {
        errorMessage += `${capitalizeString(removeUnderscores(erroredField))} ${
          payloadData.errors[erroredField]
        }. `
      })
    } else if (typeof payloadData.errors === 'string') {
      errorMessage = payloadData.errors
    }
  } else {
    errorMessage = generalStrings.genericErrorMessage
  }
  return errorMessage
}

const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
      // TODO: This is a workaround. Pending to review with the team
      if (
        action.payload &&
        action?.payload.status !== ErrorStatus.NotFound &&
        action?.payload.status !== ErrorStatus.Unauthorized &&
        action.meta.arg.endpointName !== 'checkSessionRemainingTime' &&
        action.meta.arg.endpointName !== 'getAudio' &&
        !action?.payload?.data?.errors?.internal_id?.[0]
      ) {
        const errorMessage = generateErrorMessage(action.payload.data)
        message.error(errorMessage)
        api.dispatch(setErrorAction(errorMessage))
      }
    }

    return next(action)
  }

middlewares.push(rtkQueryErrorLogger)

const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch

export { store }
