import React from 'react'
import { AddBasicItem } from 'src/components/AddBasicItem'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { DrawerWithStateProps } from 'src/components/DrawerWithState/types'
import { ViewBasicItem } from 'src/components/ViewBasicItem'
import {
  useAddRetailCategoryMutation,
  useDeleteRetailCategoryMutation,
  useEditRetailCategoryMutation,
  useLazyGetRetailCategoryQuery,
} from 'src/store/APIs/retailProducts'

import { strings } from '../strings'

interface RetailCategoriesDrawerProps extends Partial<DrawerWithStateProps> {
  open: boolean
  onCloseDrawer: () => void
  selectedId?: string
}

export const RetailCategoriesDrawer: React.FC<RetailCategoriesDrawerProps> = ({
  open,
  onCloseDrawer,
  selectedId,
  ...props
}) => {
  const [triggerAddRetailCategoryMutation, { isLoading: loadingAddCategory }] =
    useAddRetailCategoryMutation()
  const [
    triggerEditRetailCategoryMutation,
    { isLoading: loadingEditCategory },
  ] = useEditRetailCategoryMutation()
  const [
    triggerDeleteRetailCategoryMutation,
    { isLoading: loadingDeleteCategory },
  ] = useDeleteRetailCategoryMutation()

  const isLoadingRightButtonCategory = loadingAddCategory || loadingEditCategory

  return (
    <DrawerWithState
      open={open}
      onCloseDrawer={onCloseDrawer}
      selectedId={selectedId}
      RenderAdd={AddBasicItem}
      RenderView={ViewBasicItem}
      resourceName={strings.retailCategoryDrawerTitle}
      useLazyGetResource={useLazyGetRetailCategoryQuery}
      triggerDeleteResourceMutation={triggerDeleteRetailCategoryMutation}
      isLoadingLeftButton={loadingDeleteCategory}
      deleteModalTitle={strings.deleteCategoryModalTitle}
      triggerAddResourceMutation={triggerAddRetailCategoryMutation}
      triggerEditResourceMutation={triggerEditRetailCategoryMutation}
      isLoadingRightButton={isLoadingRightButtonCategory}
      {...props}
    />
  )
}
