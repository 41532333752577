import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Endpoints,
  GetImpairmentsResponse,
  GetSurvivorImpairmentsData,
  getSurvivorImpairmentsDeserializer,
  ImpairmentData,
  impairmentDeserializer,
  impairmentsDeserializer,
} from './types'

export const getImpairments = (builder: Builder) =>
  builder.query<PaginatedItems<ImpairmentData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Impairments),
    query: endpointWithFilters(Endpoints.GetImpairments),
    transformResponse: (response: GetImpairmentsResponse) =>
      impairmentsDeserializer(response),
  })

export const getSurvivorImpairments = (builder: Builder) =>
  builder.query<PaginatedItems<GetSurvivorImpairmentsData>, PaginatedParams>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Impairments }],
    query: paginatedParams(Endpoints.GetSurvivorImpairments),
    transformResponse: getSurvivorImpairmentsDeserializer,
  })

export const updateSurvivorsImpairments = (builder: Builder) =>
  builder.mutation<
    void,
    { impairments: Partial<GetSurvivorImpairmentsData>[]; id: string }
  >({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.Impairments }],
    query: ({ id, impairments }) => ({
      body: { impairments },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorsImpairments, id),
    }),
  })

export const getImpairment = (builder: Builder) =>
  builder.query<ImpairmentData, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Impairments }],
    query: ({ id }) => format(Endpoints.GetImpairment, id),
    transformResponse: ({ impairment }) => impairmentDeserializer(impairment),
  })

export const addImpairment = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string; clinical_name?: string } }
  >({
    invalidatesTags: provideListToTag(Tags.Impairments),
    query: ({ body }) => ({
      body: { impairment: body },
      method: HttpMethod.Post,
      url: Endpoints.AddImpairment,
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteImpairment = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: () => [
      { type: Tags.Impairments },
      { type: Tags.RetailProducts },
    ],
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteImpairment, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const updateImpairment = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [
      { type: Tags.Impairments },
      { type: Tags.RetailProducts },
    ],
    query: ({ body, id }) => ({
      body: { impairment: body },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateImpairment, id),
    }),
    transformResponse: performActionTransformResponse,
  })
