import { BasicImpairment } from '../impairments/types'
import { PaginatedItems, PaginationResponse, Permissions } from '../types'

export enum Endpoints {
  GetRetailProducts = '/cms/v1/global_settings/retail_products.json',
  GetRetailCategories = '/cms/v1/global_settings/retail_categories.json',
  RetailCategory = '/cms/v1/global_settings/retail_categories/%s.json',
  AddRetailCategory = '/cms/v1/global_settings/retail_categories.json',
  GetRetailProduct = '/cms/v1/global_settings/retail_products/%s.json',
  AddRetailProduct = '/cms/v1/global_settings/retail_products.json',
  EditRetailProduct = '/cms/v1/global_settings/retail_products/%s.json',
  DeleteRetailProduct = '/cms/v1/global_settings/retail_products/%s.json',
}

export interface RetailProductResponse {
  id: string
  created_at: string
  name: string
  updated_at: string
  item_description?: string
  impairments: BasicImpairment[]
  retail_categories: RetailCategory[]
}

export interface RetailProduct {
  id: string
  created_at: string
  name: string
  updated_at: string
  item_description?: string
  description?: string
  impairments?: BasicImpairment[]
  retail_categories?: RetailCategory[]
  'impairments.name': string[]
  'retail_categories.name': string[]
}

export interface GetRetailProductsResponse {
  retail_products: RetailProductResponse[]
  pagination: PaginationResponse
  model_permissions: Permissions
}

const retailProductDeserializer = (
  data: RetailProductResponse,
): RetailProduct => ({
  ...data,
  description: data.item_description,
  'impairments.name': data.impairments?.map(impairment => impairment.name),
  'retail_categories.name': data.retail_categories?.map(
    category => category.name,
  ),
})

export const retailProductsDeserializer = (
  data: GetRetailProductsResponse,
): PaginatedItems<RetailProduct> => ({
  ...data,
  items: data.retail_products.map(retailProductDeserializer),
})

export interface RetailCategory {
  id: string
  created_at: string
  name: string
  updated_at: string
}

export interface RetailCategoriesResponse {
  retail_categories: RetailCategory[]
  pagination: PaginationResponse
  model_permissions: Permissions
}

export const retailCategoriesDeserializer = (
  data: RetailCategoriesResponse,
): PaginatedItems<RetailCategory> => ({
  ...data,
  items: data.retail_categories,
})

export interface RetailProductExtended {
  id: string
  created_at: string
  name: string
  updated_at: string
  item_description?: string
  impairments?: BasicImpairment[]
  link?: string
  needs?: string
  notes?: string
  resource_description?: string
  retail_categories?: RetailCategory[]
}
