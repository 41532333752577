import { Rule } from 'antd/es/form'
import { generalStrings } from 'src/common/generalStrings'
import { yupSyncGenerator } from 'src/common/helpers'
import { URL_REGEX } from 'src/common/regex'
import * as yup from 'yup'

import { strings } from '../strings'

const schema = yup.object({
  impairment_ids: yup.array().of(yup.string()).notRequired().nullable(),
  item_description: yup.string().notRequired().nullable(),
  link: yup
    .string()
    .required(generalStrings.requiredFieldValidation)
    .matches(URL_REGEX, strings.urlValidation),
  name: yup.string().required(generalStrings.requiredFieldValidation),
  needs: yup.string().notRequired().nullable(),
  notes: yup.string().notRequired().nullable(),
  resource_description: yup.string().notRequired().nullable(),
  retail_category_ids: yup.array().of(yup.string()).notRequired().nullable(),
})

export const yupSync: Rule = yupSyncGenerator(schema)
