import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { Button } from 'src/components/Button'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { SubHeader } from 'src/components/SubHeader'
import { RouteName } from 'src/routes'
import { useLazyGetSuicidalityEmailsQuery } from 'src/store/APIs/suicidalityEmails'

import { areFiltersOn } from '../Learn/types'
import { useSuicidalityEmailsColumns } from './columns'
import { strings } from './strings'
import styles from './suicidalityEmails.module.scss'

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: strings.title, path: RouteName.SuicidalityEmails },
]

export const SuicidalityEmails: React.FC = () => {
  const { resetFilters, ...columnsAndFilters } = useSuicidalityEmailsColumns()
  const filtersOn = areFiltersOn(columnsAndFilters.filteredInfo.filters)

  return (
    <div className={styles.container}>
      <SubHeader
        className={styles.subHeader}
        routes={mockedBreadcrumbRoutes}
        section={SectionNames.Configuration}
        title={strings.title}>
        <div className={styles.subHeaderButtons}>
          <Button onClick={resetFilters} disabled={!filtersOn}>
            {generalStrings.clearFiltersButtonLabel}
          </Button>
        </div>
      </SubHeader>
      <PaginatedTable
        className={styles.table}
        useLazyQuery={useLazyGetSuicidalityEmailsQuery}
        {...columnsAndFilters}
      />
    </div>
  )
}
