import { baseApi } from 'src/store/APIs'

import { getSuicidalityEmails } from './actions'

export const suicidalityEmailsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSuicidalityEmails: getSuicidalityEmails(builder),
  }),
})

export const { useLazyGetSuicidalityEmailsQuery } = suicidalityEmailsApi
