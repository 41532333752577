import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    assessmentLabel: 'Assessment',
    assessmentPlaceholder: 'Select an assessment',
    cancel: 'Cancel',
    deploymentDateLabel: 'Deployment Date',
    deploymentDateSubLabelCarePartner: `In care partner's timezone`,
    deploymentDateSubLabelSurvivor: `In survivor's timezone`,
    differentLanguageSelectedLabel:
      'The selected assessment is not in the user’s primary language',
    dueDateLabel: 'Due Date',
    dueDateValidation: 'Due date must be after deployment date',
    noAssessmentsLanguage:
      'No assessments are available in the user’s primary language.',
    requiredValidation: 'This field is required',
    selectDatePlaceholder: 'Select date',
    title: 'Assign Assessment',
    usePrimaryLanguageLabel: 'Show only assessments in user’s primary language',
  },
})
