import { LayoutType, withLayout } from 'src/hocs/withLayout'

import { SuicidalityEmails } from './SuicidalityEmails'

const WrappedSuicidalityEmails = withLayout(
  LayoutType.NoPadding,
  SuicidalityEmails,
)

export { WrappedSuicidalityEmails as SuicidalityEmails }
