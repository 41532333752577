import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from '../types'
import {
  Endpoints,
  retailCategoriesDeserializer,
  RetailCategory,
  RetailProduct,
  RetailProductExtended,
  retailProductsDeserializer,
} from './types'

export const getRetailProducts = (builder: Builder) =>
  builder.query<PaginatedItems<RetailProduct>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.RetailProducts),
    query: endpointWithFilters(Endpoints.GetRetailProducts),
    transformResponse: retailProductsDeserializer,
  })

export const getRetailProduct = (builder: Builder) =>
  builder.query<RetailProductExtended, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.RetailProducts }],
    query: ({ id }) => format(Endpoints.GetRetailProduct, id),
    transformResponse: ({ retail_product }) => retail_product,
  })

export const addRetailProduct = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: Partial<RetailProductExtended> }
  >({
    invalidatesTags: provideListToTag(Tags.RetailProducts),
    query: ({ body }) => ({
      body: { retail_product: body },
      method: HttpMethod.Post,
      url: Endpoints.AddRetailProduct,
    }),
    transformResponse: performActionTransformResponse,
  })

export const editRetailProduct = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: Partial<RetailProductExtended>; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.RetailProducts }],
    query: ({ body, id }) => ({
      body: { retail_product: body },
      method: HttpMethod.Put,
      url: format(Endpoints.EditRetailProduct, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteRetailProduct = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: () => [{ type: Tags.RetailProducts }],
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteRetailProduct, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const getRetailCategories = (builder: Builder) =>
  builder.query<PaginatedItems<RetailCategory>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.RetailCategories),
    query: endpointWithFilters(Endpoints.GetRetailCategories),
    transformResponse: retailCategoriesDeserializer,
  })

export const getRetailCategory = (builder: Builder) =>
  builder.query<RetailCategory, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.RetailCategories }],
    query: ({ id }) => format(Endpoints.RetailCategory, id),
    transformResponse: ({ retail_category }) => retail_category,
  })

export const addRetailCategory = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: { name: string } }>({
    invalidatesTags: provideListToTag(Tags.RetailCategories),
    query: ({ body }) => ({
      body: { retail_category: body },
      method: HttpMethod.Post,
      url: Endpoints.AddRetailCategory,
    }),
    transformResponse: performActionTransformResponse,
  })

export const editRetailCategory = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [
      { type: Tags.RetailCategories },
      { type: Tags.RetailProducts },
    ],
    query: ({ body, id }) => ({
      body: { retail_category: body },
      method: HttpMethod.Put,
      url: format(Endpoints.RetailCategory, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteRetailCategory = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: () => [
      { type: Tags.RetailCategories },
      { type: Tags.RetailProducts },
    ],
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.RetailCategory, id),
    }),
    transformResponse: performActionTransformResponse,
  })
